import * as url_helpers from '../helpers/url_helper.js';
import legacy_domains from '../helpers/url_helper.js';

export default class iframeService {
  constructor(actions, array_matching, is_guest) {
    this.back_button = "hide";
    this.actions = actions;
    this.evs_app_domain = evs_app_domain;
    this.evs_app_port = evs_app_port;
    this.array_matching = array_matching;
    this.is_guest = is_guest;
  }

  //////////////////////////////////////
  /////////////// GET/SET
  //////////////////////////////////////
  setActions(actions){
    this.actions = actions;
  }

  setArrayMatching(array_matching){
    this.array_matching = array_matching;
  }

  setBackButton(back_button){
    this.back_button = back_button;
  }

  setIsGuest(is_guest){
    this.is_guest = is_guest;
  }

  getIsGuest(){
    return this.is_guest;
  }

  getBackButton(){
    return this.back_button;
  }

  //////////////////////////////////////
  /////////////// ACTIONS
  //////////////////////////////////////
  setAllUrlToAbsolute() {
    $( "a" ).each(function( i, e ) {
      var link = $(e).attr('href');

      if (link != null && typeof link != 'undefined' && !link.includes(window.location.hostname) && !url_helpers.validURL(encodeURI(link))) {
        if (link.substring(0,4) != 'tel:' && link.substring(0,6) != 'mailto:' && !url_helpers.javascriptVoidLink(link)) {
          if (link.substring(0,1) == '#')
            $(e).attr('href', window.location.href + link); //anchor
          else {
            if (link.substring(0,1) == "/") $(e).attr('href', window.location.origin + link); //e.href = window.location.hostname + l;
            else {
              var tab_paths = window.location.pathname.split("/");
              if (tab_paths.length > 0) {
                tab_paths[tab_paths.length - 1] = link;
                $(e).attr('href', window.location.origin + tab_paths.join("/"));
              }
              else {//no pathname
                $(e).attr('href', window.location.origin + "/" + link);
              }
            }
          }
        }
      }
    });
  }


  applyCss(type, name, value) {
    if (type == "id") $('#'+name).attr('style', value);
    else if (type == "class") $('.'+name).attr('style', value);
  }


  doNotEscapeFromModal(modal) {
    modal.modal({backdrop: 'static', keyboard: false});
  }


  goingOut(source, listener) {
    var domain = this.evs_app_domain;
    var port = this.evs_app_port;
    //$('a:not(".fc-day-grid-event")').off('click');//
    $('a:not(".fc-day-grid-event")').on('click', function(e) {
      if ( $(e.target) && !["post", "delete", "put"].includes($(e.target).attr('data-method')) && !["modal", "collapse"].includes($(e.target).attr('data-toggle')) && $(e.target).attr('data-dismiss') !== "modal") {
        var url = e.target.getAttribute('href');

        //exclude case of url requiring no actions
        if (url != null && typeof url != 'undefined' && !url_helpers.javascriptVoidLink(url) && url.substring(0,1) != '#' && url.substring(0,1) != '/') {
          e.preventDefault ? e.preventDefault() : e.returnValue = false;
          e.stopPropagation();

          if (exitToApp(url, domain, port)) { //1 : got to app
            if (this.is_guest == 'false')
              listener.sendPostMsg('{"action": "forceUserToLog", "value": "'+url+'"}', source);
            else
              listener.sendPostMsg('{"action": "redirect", "route": "", "url": "'+url+'", "id": ""}', source);
          } else if( urlForSiteOpener(url) === true ) { //open file/aide -> sendPostMsg
            listener.sendPostMsg('{"action": "siteOpener", "value": "'+url+'"}', source);
          } else if( switchFromCmsToLegacy(url) === true ) { //go to legacy
            var next_url = url.includes('?') ? url+'&' : url+'?';
            if (!url.includes('url_parent=')) {
              next_url += 'url_parent='+source;
            }
            window.location.href = next_url;
          } else if ( shouldCheckRedirect(url) === true ) { //2 : check then redirect if needed
            var link_url = new URL(url);
            var current_group = url_helpers.getGroup(this.array_matching, window.location.pathname + window.location.hash);
            var dest_group = url_helpers.getGroup(this.array_matching, link_url.pathname+link_url.hash);

            if (typeof current_group != 'undefined' && typeof dest_group != 'undefined' && current_group != dest_group) {
              listener.sendPostMsg('{"action": "redirect", "route": "' + dest_group + '", "url": "' + url + '", "id": ""}', source);
            } else {
              window.location.href = url;
            }
          }
          else { //3: proceed to url
            window.location.href = url;
          }
        }
      }
    }.bind(this));
  }


  processBackBtn(source, listener) {
    var sessionStorageLength = url_helpers.getNbStoredUrls();
    if (sessionStorageLength == 1) {
      this.back_button = "hide";
      listener.sendPostMsg('{"action": "backBtn", "value": "'+this.back_button+'"}', source);
    }
    else {
      sessionStorage.removeItem("url_"+sessionStorageLength);
      window.history.back();
    }
  }


  //hide elements of navigation (topnav, footer, breadcrumbs)
  // this looks for all '.hide-from-app' elements and hides them
  hideMarkedElements(){
    $('.hide-from-app').hide();
  }

  // This function tried to stylize the website for a better app display
  // It mostly removes useless spaces
  styleForApp(){
    $('.main-content').attr('style', 'margin-top: 0px!important');
  }

  removeClassGeneral() {
    $('div').removeClass("page-margin-top page-margin-bottom");
  }


  updateSessionListUrls(c_url) {
    var url_search = new URL(c_url).search.substr(1).split('&');
    var sessionStorageLength = url_helpers.getNbStoredUrls();
    if (url_search.includes("iframe_rendering=true")) {
      sessionStorage.clear();
      sessionStorage.setItem("url_1", c_url);
    }
    else {
      if (sessionStorage.getItem("url_"+sessionStorageLength) != c_url) {
        var i = sessionStorageLength + 1;
        sessionStorage.setItem("url_" + i, c_url);
      }
    }
  }


  insertUrlParams(source) {
    $( "a, form" ).each(function( i, e ) {
      if(e.tagName.toLowerCase() == "a"){
        var url = $(e).attr('href');
      } else {
        var url = $(e).attr('action');
      }
      var new_param = url.includes('url_parent=') ? "" : (url.includes('?') ? "&url_parent=" + source : "?url_parent=" + source);
      if (url != null && typeof url != 'undefined' && url.includes("/tunnel")) {
        $(e).attr('href', url + new_param);
      }
    });
  }


  processTunnelBackButton(source, listener) {
    //dont show previous btn of tunnel if first url opened
    var sessionStorageLength = url_helpers.getNbStoredUrls();
    if ( sessionStorageLength == 1 )
      $('.tunnel a.previous-question').attr('style', 'display: none!important');

    //on click on btn previous in tunnel, update links history stored in sessionStorage => update backBtn
    $('.tunnel a.previous-question').on('click', function(e) {
      if (sessionStorage.getItem("url_"+sessionStorageLength) == window.location.href)
        if (sessionStorageLength == 1) {
          this.back_button = "hide";
          listener.sendPostMsg('{"action": "backBtn", "value": "'+this.back_button+'"}', source);
        }
        else {
          sessionStorage.removeItem("url_"+sessionStorageLength);
        }
    }.bind(this));
  }

  processDataFromAnotherDomain(listener) {
    var url_parent = url_helpers.findElementInUrlParams("url_parent", window.location.search.substr(1).split('&'));
    if (typeof url_parent != 'undefined') { //tell the parent(url_parent) who is in iframe
      listener.sendPostMsg('{"action": "iframeSource", "value": "'+window.location.href+'"}', url_parent);
    }
  }

  //////////////////////////////////////
  /////////////// COOKIES
  //////////////////////////////////////
  getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
    else return undefined;
  }

  setCookie(cookie) {
    var now = new Date();
    var time = now.getTime();
    time += 2 * 60 * 60 * 1000;
    now.setTime(time);
    document.cookie = cookie + "; expires=" + now + "; path=/; SameSite=Lax; domain=.envoituresimone.com";
  }
}

//////////////////////////////////////
/////////////// CHECKS
//////////////////////////////////////
function shouldCheckRedirect(link)//true if we need to go to check redirect
{
  var link_value = link || "";
  if ( link_value == "#" || link_value == window.location.origin + "#" ){//add here new conditions if needed
    return false;
  }
  else {
    return true;
  }
}

function exitToApp(url, app_domain, app_port) //true if url links to app
{
  var link = new URL(url);
  if (link.hostname == app_domain && link.port == app_port)
    return true;
  else
    return false;
}

function switchFromCmsToLegacy(url) //check on origin, not in search params
{
  var found = false;
  var link = new URL(url);
  var url_without_params = link.origin + link.pathname;
  legacy_domains.forEach(function(elt) {
    if(url_without_params.includes(elt)) found = true;
  });

  return found;
}

function urlForSiteOpener(url) {
  if ( url.includes('.pdf') || url.includes('aide.envoituresimone.com') || !url.includes('.envoituresimone.com') ) {
    return true;
  } else {
    return false;
  }
}