// Address JS
var geocompleteMeetingPointsMaps = function(input) {
    if (input.length > 0) {
        return input.geocomplete({
            details: '#geocomplete-for-map-js',
            types: ['geocode', 'establishment']
        }).bind('geocode:result', function(event, result) {
            input.val($(this).val());
            $('#geocomplete-for-map-js').submit();
        });
    }
};

var geocompleteButton = function(input) {
    let btn = $(".btn-geocomplete-js");

    if (btn) {
        btn.click(function () {
            input.trigger("geocode");
        });
    }
};


var geocompleteClearInput = function(input) {
    var btn = $(".btn-geocomplete-js");

    var clearButton = $('.clear-input-js');
    input.on('keyup', function() {
        if (this.value.length > 0) {
            clearButton.show()
        } else {
            clearButton.hide();
            btn.show();
        }
    });

    clearButton.click(function(){
        input.val("");
        clearButton.hide();
        input.focus();
        btn.show();
    })
};

var initGeocomplete = function() {
    let input = $(".address-for-map-js");

    if (input.length > 0) {
        geocompleteButton(input);
        geocompleteClearInput(input);
        geocompleteMeetingPointsMaps(input);
    }
};
$(document).ready(function() {
    initGeocomplete();
});
