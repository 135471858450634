// Footnote JS


// Calls - hooks
$(document).ready(function() {
    var footnotes = document.getElementsByClassName('footnote');
    if (footnotes.length > 0)
    {
        for (let footnote of footnotes) {
            processFootnoteOnPage(footnote);
        };
    }

    //remove useless 'sup' link added in foonotes
    clearFootnoteContent();
});


// Parse a footnote, gets the original text, replace the index and add the index to the matching page text
function processFootnoteOnPage(footnote)
{
    span = footnote.getElementsByTagName("span")[0];
    index = span.getAttribute("index");
    originalText = span.getAttribute("data-content");

    if(originalText == "") // safety if you forgot to fill the footnote original text
    {
        return
    }

    //index
    span.innerText = index + '.';

    //remove data-content
    span.setAttribute("data-content", "");

    // Replace original text
    var re = new RegExp(originalText, "g");
    var linkToFootnote = "<a class=\"footnote-link\" href=\"#footnotes\">" + index + "</a>";
    document.body.innerHTML = document.body.innerHTML.replace(re, originalText + "<sup>" + linkToFootnote + "</sup>");
}

function clearFootnoteContent()
{
    var linksToFootnote = document.querySelectorAll('#footnotes sup');
    if (linksToFootnote.length > 0)
    {
        for (let link of linksToFootnote) {
            link.parentNode.removeChild(link);
        };
    }
}