import iframeListener from './iframe_listener.js';
import iframeService from './iframe_service.js';
import * as Mustache from "mustache";


var is_guest = "false";

function render_template(template_name, container, data)  {
  var template = document.getElementById(template_name).innerHTML;
  var rendered = Mustache.render(template, data);

  container.innerHTML = rendered;
}

function generate_connect_buttons() {
  let container = document.getElementById("container-omniauth-connect-js")
  let template_name = "template-omniauth-connect-js"
  let data = null;

  if(container) {
    render_template(template_name, container, data)
  }
}

//////////////////////////////////////
/////////////// READY
//////////////////////////////////////
$(document).ready(function() {
  is_guest = current_user.is_guest;

  var iframe_service = new iframeService([], {}, is_guest);
  var iframe_listener = new iframeListener(iframe_service, current_user);
  var iframe_rendering = iframe_service.getCookie('iframe_rendering');

  if (window.location.href.includes('iframe_rendering=true') || iframe_rendering === "true") {
    if (window.top !== window.self) {
      //console.log('current url : ', window.location.href);

      iframe_service.hideMarkedElements();
      iframe_service.styleForApp();
      iframe_service.updateSessionListUrls(window.location.href);
      if (window.location.search.includes('url_parent=')) {
        iframe_service.processDataFromAnotherDomain(iframe_listener);
      }
      iframe_listener.listenEvents();
    }
  }
  else {
    generate_connect_buttons();
    if (window.top !== window.self) { //if(iframe) but no cookie => postMessage redirect to route: logout, url: ""
      window.addEventListener('message', function(e) {
        if (e.origin.includes('envoituresimone') || e.origin.includes('herokuapp') || e.origin.includes('localhost')) {
          console.error("Error : inside iframe but no cookie iframe/iframe_rendering=true");
        }
      });
    }
  }

});
