// TeacherIncomeSimulator JS

// Computing oil price
function getOilPrice(){
    var url = 'https://api.openeventdatabase.org/event/1c1a4ddd-48e0-45a5-9944-5164700cd5ed';

    var response = $.ajax({
        url: url,
        async: false
    }).responseText

    return JSON.parse(response)['properties']['carburants'][0]['prix'];
}

var oilPrice = undefined;

// Manage
function teacherWagesSimulator()  {
    var container;
    if ($('#teacher_income_simulator')) {
        simulateTeacherWages();
        container = $("#teacher_income_simulator #wages-wrapper");
        container.find("form").submit(function(e) {
            return e.preventDefault();
        });
        $("#teacher_income_simulator #wages-wrapper #hours-per-week-input,input[name='accre']").on('change keyup', function() {
            return simulateTeacherWages();
        });
    }
}

// Pretty print
function setNumberDisplay(x) {
    return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
};


function  simulateTeacherWages() {
    var annualKms, ca, container, container_modal, expenses, hoursPerWeek, insurance, monthHours, monthOil, taxInputValue, taxRate, taxes, vehicule, wages;

    container = $("#teacher_income_simulator #wages-wrapper");
    container_modal = $("#teacherWagesSimulatorDetails");
    hoursPerWeek = parseInt(container.find("#hours-per-week-input").val());
    if (isNaN(hoursPerWeek)) {
        hoursPerWeek = 0;
    }
    monthHours = hoursPerWeek * 4.33
    ca = monthHours * parseInt(container_modal.find("#wages-per-hour").text())
    annualKms = monthHours * 12 * 30
    monthOil = monthHours * oilPrice * 5 * 30 / 100
    vehicule = parseInt(container_modal.find("#vehicule").text())
    insurance = parseInt(container_modal.find("#insurance").text())
    expenses = vehicule + monthOil + insurance

    taxInputValue = $("input[name='accre']:checked").val() || "without-accre"

    if (taxInputValue === "with-accre") {
        taxRate = 0.11;
    } else {
        taxRate = 0.22;
    }

    taxes = ca * taxRate;
    wages = ca - expenses - taxes;

    container_modal.find("#ca").html(setNumberDisplay(ca));
    container_modal.find("#month-hours").html(setNumberDisplay(monthHours));
    container_modal.find("#annual-kms").html(setNumberDisplay(annualKms));
    container_modal.find("#expenses").html(setNumberDisplay(expenses));
    container.find("#oil-price").html(oilPrice);
    container_modal.find("#month-oil").html(setNumberDisplay(monthOil));
    container_modal.find("#taxes").html(setNumberDisplay(taxes));
    container.find("#wages").hide();
    container.find("#wages").html(setNumberDisplay(wages));

    return container.find("#wages").fadeIn();
};


// Calls - hooks
$(document).ready(function() {
    if ($('#teacher_income_simulator').length > 0)
    {
        oilPrice = getOilPrice();
        teacherWagesSimulator();
    }
})
