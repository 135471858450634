import "footnote/footnote"; // Footnote component - !! WARNING !! ALWAYS THE FIRST IMPORT
import "offers/offers";
import "offer/offer";
import "text_image/text_image"; // TextImage component
import "video/video"; // Video component
import "cta/cta"; // Cta component
import "download_app/download_app"; // DownloadApp component
import "text/text"; // Text component
import "image/image"; // Image component
import "image_card/image_card"; // Card component
import "price_card/price_card"; // PriceCard component
import "teacher_income_simulator/teacher_income_simulator"; // TeacherIncomeSimulator component
import "meta/meta"; // Meta component
import "offers/offers"; // Offers component
import "address/address"; // Address component
import "faq/faq"; // Faq component
import "rich_text/rich_text"; // RichText component
import "picto/picto"; // Picto component
import "recommendation/recommendation"; // Recommendation component
import "cta_image/cta_image"; // CtaImage component
import "steps/steps"; // Steps component

import "checklist/checklist"; // Checklist component
import "slider_generic/slider_generic"; // SliderGeneric component
import "text_video/text_video"; // TextVideo component
import "slider_teacher/slider_teacher"; // SliderTeacher component

import "page_settings/page_settings"; // PageSetting component


import "heading/heading"; // Heading component
import "black/black"; // Black component
import "average_rating/average_rating"; // AverageRating component
import "rating_list/rating_list"; // RatingList component
import "department_list/department_list"; // DepartmentList component
import "fountain_form/fountain_form"; // FountainForm component
import "pre_footer/pre_footer"; // PreFooter component
import "tabs/tabs"; // Tabs component