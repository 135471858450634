// SliderTeacher JS
import * as Flickity from 'flickity/dist/flickity.pkgd'

// jQuery
// pass in the element, $element[0], not the jQuery object
$(document).ready(function() {
    for (var elt of $('.slider-teacher-carrousel-js')) {
        
        let options = {
            pageDots: false,
            cellAlign: "left",
            imagesLoaded: true,
            groupCells: 1,
            wrapAround: true
        };

        new Flickity(elt, options)
    }
});