// Tabs JS
$(document).ready(function(){
    $('.nav-pills .nav-link').click(function(){
        $('.nav-pills .nav-link').removeClass('active');
        $(this).addClass('active');
        $.each($('[data-associated-tab]'), function(index, value){
            value.hidden = true;
        });
        $.each($("[data-associated-tab='"+this.getAttribute('data-tab-id')+"']"), function(index, value){
            value.hidden = false;
        });
    });
});
