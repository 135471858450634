import msgReceived from './iframe_message.js';
import * as url_helpers from '../helpers/url_helper.js';

export default class iframeListener {
  constructor(iframe_service, current_user) {
    this.is_debug = false;
    this.array_matching = {};
    this.actions =[];
    this.service = iframe_service;
    this.current_user = current_user;
  }

  // --------------------------------------------------
  //                     GET/SET
  // --------------------------------------------------
  getDebugMode() {
    return this.is_debug
  };

  getActions() {
    return this.actions
  };

  setActions(actions){
    this.actions = actions;
  }

  getArrayMatching() {
    return this.array_matching
  };

  setDebugMode(is_debug) {
    this.is_debug = is_debug;
  };

  // --------------------------------------------------
  //                     LISTEN & TALK
  // --------------------------------------------------
  listenEvents() {
    window.addEventListener('message', function(event) {
      if (event.origin.includes('app.envoituresimone') || event.origin.includes('app-dev.envoituresimone') || event.origin.includes('herokuapp') || event.origin.includes('localhost') || this.is_debug) {
        if (event.origin != window.location.origin) {
          //msg received
          var msg_received = this.is_debug ? msgReceived : event.data;
          var str_msg = JSON.stringify(msg_received);
          var login_url_with_token = this.current_user.login_url;
          try {
            var msg = JSON.parse(str_msg);
          }
          catch (error) {
            console.error("tried to JSON.parse stringified msg and got error ", error);
          }

          //array matching url
          if(Object.keys(this.array_matching).length == 0 && msg.hasOwnProperty("arrayMatching")) this.array_matching = msg["arrayMatching"]; //set if not set yet
          this.service.setArrayMatching(this.array_matching);

          this.setBackBtn(event); //back_button

          if (this.current_user.is_guest == 'false') {
            this.notifyPpuResolved(login_url_with_token, event.origin);
            this.notifyUserLogged(login_url_with_token, event.origin);
          } else {
            this.setClientId(msg);
          }
          this.pushFromMsgToDatalayer(msg);

          //new actions updateStudent//
          if (this.sendUpdateStudent() === true) {
            this.sendPostMsg('{"action": "updateStudent", "value": ""}', event.origin);
          }

          this.service.goingOut(event.origin, this); //test on all click except exceptions, including forceLogOnClick(e.origin);

          this.triggerActions(msg, event.origin); //get actions from msg/ask service to process

          //event source url
          this.service.insertUrlParams(event.origin);

          this.service.processTunnelBackButton(event.origin, this); //manage previous btn in tunnel/synchronize it with back_btn
        }
      }
    }.bind(this));
  }


  notifyPpuResolved(login_url_with_token, source) {
    if (window.location.search.includes('ppu_resolved=true')) {
      this.sendPostMsg('{"action": "userPpuResolved", "value": "'+login_url_with_token+'"}', source);
    }
  }

  notifyUserLogged(login_url_with_token, source) {
    this.sendPostMsg('{"action": "userLogged", "value": "'+login_url_with_token+'"}', source);
  }

  setClientId(msg) {
    if (msg.hasOwnProperty("clientId")) {
      if (typeof ga === 'function' && ga_tracking_id && ga_tracking_id.toString().length > 0) {
        ga('create', ga_tracking_id, 'auto', {
          clientId: msg["clientId"]
        });
      }
    }
  }

  pushFromMsgToDatalayer(msg) {
    window.dataLayer = window.dataLayer || [];
    var data = {};
    var data_fields = ['clientId', 'ionic_platform', 'ionic_platforms']
    data_fields.forEach(function(field){
      if (msg.hasOwnProperty(field)) data[field] = msg[field];
    });
    if (Object.keys(data).length > 0) {
      data['event'] = 'iframe_pageview';
      window.dataLayer.push(data);
    }
  }

  triggerActions(msg, source) {
    if (msg.hasOwnProperty("action") && msg["action"] == "goBack") { //back history
      this.service.processBackBtn(source, this);
    }
    else {
      var res = this.getActionsFromMsg(msg["nav"], this.actions);
      this.setActions(res);
      this.service.setActions(res);

      for(var r in res){
        var actionType = res[r].type;
        switch(actionType) {
          case "css":
            if(res[r].hasOwnProperty('id') && res[r].id != '') this.service.applyCss("id", res[r].id, res[r].value);
            else if(res[r].hasOwnProperty('class') && res[r].class != '') this.service.applyCss("class", res[r].class, res[r].value);
            break;
          case "redirect":
            //
            break;
          case "modale":
            //
            break;
          case "absoluteUrl":
            this.service.setAllUrlToAbsolute();
            break;
          default:
            console.log('action type not found');
        }
      }
    }
  }


  sendPostMsg(params, source) {
    if (this.is_debug) {
      console.log(params);
    }
    else {
      if (Array.isArray(source)) {
        for (var i = 0; i < source.length; i++) {
          window.parent.postMessage(params, source[i]);
        }
      }
      else {
        window.parent.postMessage(params, source);
      }
    }
  }

  setBackBtn(event) {
    var sessionStorageLength = url_helpers.getNbStoredUrls();
    var back_button = (sessionStorageLength > 1) ? "show" : "hide";
    this.service.setBackButton(back_button);
    this.sendPostMsg('{"action": "backBtn", "value": "'+back_button+'"}', event.origin);
  }

  getActionsFromMsg(data, actions) {
    for (var sub_data in data) {
      if (sub_data != 'url') {
        this.getActionsFromMsg(data[sub_data], actions);
      }
      else {
        var item = data[sub_data];
        if (item.hasOwnProperty('action')) {
          if (item.hasOwnProperty('value')) {
            var link = (item['value'] == "*") ? item['value'] : new URL(window.location.origin + item['value']);
            if (link == '*' || link.pathname == window.location.pathname) {
              for (var action in item['action']) {
                actions.push(item['action'][action]);
              }
            }
          }
          else if (item.hasOwnProperty('regex')) {
            if (window.location.pathname.match(new RegExp(item['regex'].replace('/','\/')))) {
              for (var action in item['action']) {
                actions.push(item['action'][action]);
              }
            }
          }
        }
      };
    }
    return actions;
  }

  sendUpdateStudent() {
    var histo_nav = Object.values(sessionStorage);
    var urls_for_student_update = ["/boutique/paiement/", "/tunnel/felicitations", "/examen-code/nouveau", "/examen-code/examens"];
    var found = false;

    for (var url in histo_nav) {
      for (var item in urls_for_student_update) {
        if ( histo_nav[url].includes(urls_for_student_update[item]) ) found = true;
      }
    }
    return found;
  }
}