// SliderGeneric JS
import * as Flickity from 'flickity/dist/flickity.pkgd'

// jQuery
// pass in the element, $element[0], not the jQuery object
$(document).ready(function() {
 for (var elt of $('.slider-generic-carrousel-js')) {
  var flk = new Flickity(elt, $(elt).data('flickity'))
 }
})
