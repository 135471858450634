export function validURL(str) {
  var pattern = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.,@~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(str);
}

export function getGroup(data, search) {
  var res = undefined;
  $.each(data, function(key,value) {
    for (var v in value) {
      if ((typeof value[v] === 'string' || value[v] instanceof String) && value[v] == search) {
        res = key;
        return false;
      }
      else if (typeof value[v] == 'object' && value[v].hasOwnProperty('regex') && search.match(new RegExp(value[v].regex.replace('/','\/')))) {
        res = key
        return false;
      }
    }
  });
  return res;
}

export function insideWhiteList(whitelist, search) {
  var found = false
  whitelist.forEach(elt => {
    if (((typeof elt === 'string' || elt instanceof String) && elt == search) || (typeof elt == 'object' && elt.hasOwnProperty('regex') && search.match(new RegExp(elt.regex.replace('/','\/'))))){
      found = true;
    }
  });
  return found;
}

export function inArray(target, array)
{
  for(var i = 0; i < array.length; i++)
  {
    if(array[i].includes(target))
    {
      return true;
    }
  }

  return false;
}

export function findElementInUrlParams(elt, array){
  var result = undefined;
  for (var item in array) {
    var tmp = array[item].split("=");
    if (tmp[0] === elt) result = tmp[1];
  }
  return result;
}

export function javascriptVoidLink(link) {
  var javascript_void_link_regex = /^javascript:void\(0\)(;)*$/;
  return javascript_void_link_regex.test(link);
}

export function getNbStoredUrls() {
  let keys = Object.keys(sessionStorage);
  let nb_filtered_keys = keys.filter(key => key.match(/url_[0-9]/));
  return nb_filtered_keys.length;
}

var legacy_domains = ["dev.envoituresimone.com", "account.envoituresimone.com", "account-dev.envoituresimone.com", "driving.envoituresimone.com", "driving-dev.envoituresimone.com"];
export default legacy_domains;
