var scrollToAnchor = function() {
  let classList = [].concat(...[...document.querySelectorAll('*')].map(elt => [...elt.classList]));
  let anchorContainer = classList.filter(el => el.includes('anchor-container'));

  anchorContainer.forEach((el) => {
    let divElements = document.getElementsByClassName(el);
    let anchorLinks = divElements[0].getElementsByTagName('a');
    [...anchorLinks].forEach((anchorLink) => {
      anchorLink.onclick = (e) => {
        e.preventDefault();
        let articleContainer = document.getElementById(anchorLink.hash.substring(1)); // je récupère le hash du lien pour trigger l'elt html de destination
        if (/Android|iPhone/i.test(navigator.userAgent)) { // si device
          document.body.scrollTop = articleContainer.offsetTop - 72;
        } else {
          window.scroll(0, articleContainer.offsetTop - 72);
        }
      }
    });
  });
}

$(document).ready(function(){
  scrollToAnchor();
});