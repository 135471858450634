import * as Flickity from 'flickity/dist/flickity.pkgd'

var smart_position_breadcrumbs = function(breadcrumb) {
    if (document.getElementsByClassName('driving-school-by-dpt')[0] || document.getElementsByClassName('driving-school-by-city')[0]) {
        // Get navbar height
        let navBar = $('.navbar').outerHeight() || 0;

        // Get flash-messages height
        let flashMsg = 0;
        if ($('#flash-messages')) {
            flashMsg = $('#flash-messages').outerHeight() || 0;
        }

        // set margin top
        let marginTop = navBar + flashMsg;
        breadcrumb.css('margin-top', String(marginTop) + 'px');

        //remove extra margin main-content
        let mainContent = document.getElementsByClassName('main-content')[0];
        if (mainContent) {
            mainContent.setAttribute("style", " margin-top:0px");
        }
    }
};

var slider_teachers = function() {
    for (var elt of $('.slider-teacher-carrousel-js')) {
        let options = {pageDots: false, cellAlign: "left", imagesLoaded: true, groupCells: 1};

        new Flickity(elt, options)
    }
}

$(document).ready(function() {
    let breadcrumb = $('#breadcrumbs-js');

    if (breadcrumb) {
        smart_position_breadcrumbs(breadcrumb);

        document.addEventListener('DOMContentLoaded', function (event) {
            smart_position_breadcrumbs(breadcrumb);
        });

        window.addEventListener('resize', function (event) {
            smart_position_breadcrumbs(breadcrumb);
        });
    }
    slider_teachers()
});