// Recommendation JS
import * as Flickity from 'flickity/dist/flickity.pkgd'


// jQuery
// pass in the element, $element[0], not the jQuery object
$(document).ready(function() {
    for (var elt of $('.block-press-recommendation.recommendation-carrousel-js')) {
        let options = {
            autoPlay: false,
            cellAlign: "left",
            contain: true,
            prevNextButtons: true,
            pageDots: false,
            wrapAround: true,
            freeScroll: true
        };

        new Flickity(elt, options)
    }

    for (var elt of $('.block-user-recommendation.recommendation-carrousel-js')) {
       let options = {autoPlay: true, cellAlign: "left", contain: true, prevNextButtons: false, pageDots: false, wrapAround: false};

       new Flickity(elt, options)
    }

    for (var elt of $('.block-partner-recommendation.recommendation-carrousel-js')) {
       let options = {autoPlay: true, cellAlign: "left", contain: true, prevNextButtons: false, pageDots: false, wrapAround: false};

       new Flickity(elt, options)
    }

    (typeof changeTitle !== 'undefined' && changeTitle) ? $('.block-recommendation h2').text('Nos élèves donnent leur avis') : null;
})
