//example from teacher-app
var msgReceived = {
  "arrayMatching": {
    "lecons": ["/lessons", "/lessons/calendrier", {"regex":"/lesson/[0-9]+"}],
    "proposer": ["/lessons#new-lesson-by-teacher"],
    "disponibilites": ["/disponibilites"],
    "profil": ["/enseignants/mon-compte", "/enseignants/lieux-desservis", "/factures", "/users/edit"]
  },
  "nav": {
    "general": {
      "url": {
        "value": "*",
        "action": [
          {"type": "redirect", "value": "", "class": "alert-link", "whitelist": []},
          {"type": "absoluteUrl", "value": ""}
        ]
      }
    },
    "lessons": {
      "liste": {
        "url": {
          "value": "/lessons",
          "action": [
            {"type": "css", "value": "display: none", "id": "btn-new-lesson"},
            {"type": "redirect", "value": "/lessons#new-lesson-by-teacher", "whitelist": ["/lessons", "/lessons/calendrier", {"regex":"/lesson/[0-9]+"}]},
            {"type": "css", "value": "max-width: 100%;width: 100%;height: 100%", "id": "confirm-proposal-modal-dialog"},
            {"type": "css", "value": "border-radius: 0;height: 100%", "id": "confirm-proposal-modal-content"}
          ]
        }
      },
      "calendrier": {
        "url": {
          "value": "/lessons/calendrier",
          "action": [
            {"type": "css", "value": "display: none", "id": "btn-lessons-list"},
            {"type": "css", "value": "display: inline-block", "id": "btn-new-lesson"},
            {"type": "redirect", "value": "/lessons", "whitelist": ["/lessons", "/lessons/calendrier", {"regex":"/lesson/[0-9]+"}]}
          ]
        }
      },
      "lesson": {
        "url": {
          "regex": "/lesson/[1-9]+",
          "action": []
        }
      }
    },
    "proposer": {
      "url": {
        "value": "/lessons#new-lesson-by-teacher",
        "action": [
          {"type": "modale", "value": "show", "id": "new-lesson-by-teacher"},
          {"type": "css", "value": "display: none", "id": "btn-cancel"},
          {"type": "css", "value": "display: none", "id": "btn-close-modal"},
          {"type": "css", "value": "max-width: 100%;width: 100%;height: 100%", "id": "new-proposal-modal-dialog"},
          {"type": "css", "value": "border-radius: 0;height: 100%", "id": "new-proposal-modal-content"}
        ]
      }
    },
    "disponibilites": {
      "url": {
        "value": "/disponibilites",
        "action": []
      }
    },
    "profil": {
      "infos_perso": {
        "url": {
          "value": "/enseignants/mon-compte",
          "action": [
            {"type": "css", "value": "display:none", "id": "btn-facturation"},
            {"type": "css", "value": "display:none", "id": "btn-lieux-desservis"}
          ]
        },
        "modif-mot-de-passe": {
          "url": {
            "value": "/users/edit",
            "action": []
          }
        }
      },
      "lieux-desservis": {
        "url": {
          "value": "/enseignants/lieux-desservis",
          "action": [
            {"type": "css", "value": "display:none", "id": "back-link"}
          ]
        }
      },
      "facturation": {
        "url": {
          "value": "/factures",
          "action": []
        }
      }
    }
  }
};
export default msgReceived;